<template>
    <v-container>
        <v-dialog
        v-model="this.display"
        height="300px"
        width="400px"
        @click:outside="redirectHome">
        <v-card
            class="mx-auto">
            <!-- <v-img
            src="/images/taxpod_vision_logo.png"
            height="90px"
            ></v-img>
            <hr/> -->
            <v-card-title
            class="d-flex justify-center text-h4 mt-6">
            <v-icon
            color="error"
            size="100">
                mdi-alert
            </v-icon>
            Unauthorized User
            </v-card-title>
            <v-card-subtitle
            class="d-flex justify-center text-center">
            Sorry but your account is not authorized for TaxPOD VISION
            </v-card-subtitle>
            <v-card-actions
            class="d-flex justify-center">
                <v-btn
                color="primary"
                @click="redirectHome">
                Go to login page
                </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    data:()=>({
        display:true,
    }),
    created(){
        // this.$router.push({name:'PageGuestHome'});
    },
    methods:{
        redirectHome(){
            this.$store.dispatch("logout");
            this.$router.push({name:'PageGuestHome'});
        }
    }
}
</script>